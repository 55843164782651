/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme, useThemedCss } from "src/hooks/styles";
import { IconLogo, Logo } from "src/components/ui/logo";
import Navbar from "react-bootstrap/esm/Navbar";
import Text from "src/components/ui/text";
import { useTranslation } from "react-i18next";
import MenuItem from "src/components/ui/menu/MenuItem";
import Icon from "src/components/ui/icons";
import { useAuthStore } from "src/store/account";
import styles, { IStyles } from "src/components/ui/menu/SideMenu.styles";
import { useMenuStore } from "src/store/menu";
import { useBreakpointIsHigherOrEqual, useBreakpointIsLowerOrEqual } from "src/hooks/responsive";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useLogout } from "src/hooks/account";
import MenuItemDropdown from "src/components/ui/menu/MenuItemDropdown";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import Dropdown from "react-bootstrap/Dropdown";
import { useTutorialStore } from "src/store/tutorial";

type SideMenuProps = React.HTMLProps<HTMLDivElement> & {
  style?: React.CSSProperties;
  className?: string;
  mobileMenuContent?: React.ReactNode;
};

const SideMenu: React.FC<SideMenuProps> = ({ style, mobileMenuContent, ...props }) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointIsLowerOrEqual("sm");
  const {
    header: { height: headerHeight },
    menu: { sideWidth, sideWidthOpen },
  } = useTheme();
  const location = useLocation();

  const { isAuthenticated, isSuperuser } = useAuthStore();

  const hasAgentAccess = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_DASHBOARD);

  const { isOpen, toggle: toggleMenu, setIsOpen } = useMenuStore();

  const themedCss = useThemedCss<IStyles>(styles);

  const { setReadyComponents } = useTutorialStore();

  React.useEffect(() => {
    setReadyComponents(["#layout-aside"]);
  }, []);

  const containerStyles = React.useMemo<React.CSSProperties>(() => {
    if (isMobile) {
      if (!isOpen) {
        return {
          minWidth: sideWidth,
          maxWidth: sideWidth,
          width: sideWidth,
          minHeight: 0,
          boxShadow: "none",
          height: headerHeight,
        };
      }

      return {
        minWidth: "90vw",
        maxWidth: "90vw",
        width: "90vw",
      };
    }
    if (!isOpen) {
      return {
        minWidth: sideWidth,
        maxWidth: sideWidth,
        width: sideWidth,
      };
    }
    return {
      minWidth: sideWidthOpen,
      maxWidth: sideWidthOpen,
      width: sideWidthOpen,
    };
  }, [isOpen, isMobile]);

  const toggleStyles = React.useMemo<React.CSSProperties>(() => {
    if (!isOpen) {
      return {
        transform: "translate(-50%, -50%) rotate(180deg)",
      };
    }
    return {};
  }, [isOpen]);

  const navbarStyles = React.useMemo<React.CSSProperties>(() => {
    if (isMobile && !isOpen) {
      return {
        display: "flex",
        justifyContent: "center",
        borderRight: "1px solid #E7E7E7",
      };
    }
    if (isOpen) {
      return {
        paddingLeft: 40,
      };
    }
    return {
      display: "flex",
      justifyContent: "center",
    };
  }, [isOpen, isMobile]);

  const handleLogout = useLogout();

  React.useEffect(() => {
    if (isMobile && isOpen) {
      setIsOpen(false);
    }
  }, [location.pathname, isMobile]);
  const handleLogoClick: React.MouseEventHandler = React.useCallback(
    (event) => {
      if (isMobile && !isOpen) {
        setIsOpen(true);
        event.preventDefault();
      }
    },
    [isMobile, isOpen],
  );

  return (
    <>
      <aside id="layout-aside" css={themedCss.container} style={{ ...style, ...containerStyles }} {...props}>
        <Navbar css={themedCss.navbar} style={navbarStyles}>
          <Text.Link to="/" onClick={handleLogoClick}>
            {isOpen ? <Logo /> : <IconLogo />}
          </Text.Link>
          <button type="button" css={themedCss.toggle} onClick={toggleMenu} style={toggleStyles}>
            <span css={themedCss.toggleIcon}>
              <Icon name="arrow-left" size={10} variant="inherit" />
            </span>
          </button>
        </Navbar>
        {(!isMobile || (isMobile && isOpen)) && (
          <div css={themedCss.menuContainer}>
            {isMobile && mobileMenuContent && mobileMenuContent}
            <div css={themedCss.menu}>
              <MenuItemDropdown
                title={t("components.side_menu.settings_title", "Oppurtunities")}
                iconName="search"
                isOpen={isOpen}
                id="side-menu-opportunities-dropdown"
              >
                <MenuItem
                  to="/arbitrage-properties/"
                  title={t("components.side_menu.arbitrage_properties_title", "Arbitrage")}
                  iconName="shining"
                  isOpen={isOpen}
                />
                <MenuItem
                  to="/co-host-properties/"
                  title={t("components.side_menu.co_host_properties_title", "Co-Host")}
                  iconName="communication"
                  isOpen={isOpen}
                />
                <MenuItem
                  to="/apartments/"
                  title={t("components.side_menu.apartments_title", "Apartments")}
                  iconName="tools"
                  isOpen={isOpen}
                />
              </MenuItemDropdown>
              <MenuItem
                to="/properties/"
                title={t("components.side_menu.find_properties_title", "Rental markets")}
                iconName="property-search"
                isOpen={isOpen}
                id="rental-markets-menu-item"
              />
              <MenuItem
                to="/deals/"
                title={t("components.side_menu.your_deals_title", "Your deals")}
                iconName="note"
                isOpen={isOpen}
                id="side-menu-deals-link"
              />
              <MenuItem
                to="/arbitrage-calculator/"
                title={t("components.side_menu.analyze_property_title", "Analyze a property")}
                iconName="chart"
                id="side-menu-analyze-property-link"
                isOpen={isOpen}
              />
              <MenuItemDropdown
                title={t("components.side_menu.service_providers_title", "Providers")}
                iconName="time-oclock"
                id="side-menu-providers-dropdown"
                isOpen={isOpen}
              >
                <MenuItem to="/vendors/" title={t("components.side_menu.vendors_title", "Vendors")} iconName="home-up" isOpen={isOpen} />
                <MenuItem
                  to="/partners/"
                  title={t("components.side_menu.partners_title", "Partners")}
                  iconName="paper-clip"
                  isOpen={isOpen}
                />
                <MenuItem
                  to="/my-people/"
                  title={t("components.side_menu.my_people_title", "My people")}
                  iconName="heart"
                  isOpen={isOpen}
                />
              </MenuItemDropdown>
              <MenuItem
                to="/learning-hub/"
                title={t("components.side_menu.learning_hub_title", "Learning hub")}
                iconName="pie-chart"
                id="side-menu-learning-hub-link"
                isOpen={isOpen}
              />
              <MenuItem
                to="/str-database/"
                title={t("components.side_menu.str_database_title", "STR database")}
                iconName="documents"
                id="side-menu-str-database-link"
                isOpen={isOpen}
              />
              {isAuthenticated && isSuperuser && (
                <MenuItem
                  to="/str-analyzer/"
                  title={t("components.side_menu.new_str_database_title", "STR Analyzer")}
                  iconName="documents"
                  id="side-menu-new-str-database-link"
                  isOpen={isOpen}
                />
              )}
              <MenuItemDropdown
                title={t("components.side_menu.settings_title", "Settings")}
                iconName="gear"
                isOpen={isOpen}
                activeTo="/settings/"
              >
                <MenuItem
                  to="/settings/profile/"
                  title={t("components.side_menu.profile_title", "Profile")}
                  iconName="gear"
                  isOpen={isOpen}
                  menuLevel={2}
                />
                <MenuItem
                  to="/settings/billing/"
                  title={t("components.side_menu.billing_title", "Billing")}
                  iconName="wallet"
                  isOpen={isOpen}
                  menuLevel={2}
                />
                <MenuItem
                  to="/settings/membership/"
                  title={t("components.side_menu.membership_title", "Membership")}
                  iconName="documents"
                  isOpen={isOpen}
                  menuLevel={2}
                />
                <MenuItem
                  to="/settings/orders/"
                  title={t("components.side_menu.orders_title", "Orders")}
                  iconName="plus"
                  isOpen={isOpen}
                  menuLevel={2}
                />
              </MenuItemDropdown>
              {isAuthenticated && isSuperuser && (
                <MenuItem to="/admin/" title={t("components.side_menu.admin_panel_title", "Admin panel")} iconName="lock" isOpen={isOpen} />
              )}
              {isAuthenticated && hasAgentAccess && (
                <MenuItem to="/agent/" title={t("components.side_menu.agent_panel_title", "Agent panel")} iconName="lock" isOpen={isOpen} />
              )}
            </div>
            {isAuthenticated && (
              <div css={themedCss.footerMenu}>
                <MenuItem to="/help/" title={t("components.side_menu.help_title", "Help")} iconName="help" isOpen={isOpen} />
                <MenuItem
                  onClick={handleLogout}
                  title={t("components.side_menu.log_out_title", "Log out")}
                  iconName="exit"
                  isOpen={isOpen}
                />
              </div>
            )}
          </div>
        )}
      </aside>

      {isMobile && isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.18 }}
          css={themedCss.overlay}
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default SideMenu;
