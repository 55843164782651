/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useAuthStore } from "src/store/account";
import PagePreloader from "src/components/loaders";
import { useBooleanState } from "src/hooks/boolean";
import { useTranslation } from "react-i18next";
import { useProfileHasAnyPermission, useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";

interface IPlanWrapper {
  children: JSX.Element;
  permission: MembershipPermission | MembershipPermission[];
  previousComponent?: JSX.Element;
}

const PlanWrapper: React.FC<IPlanWrapper> = ({ children, permission, previousComponent }) => {
  const { isAuthenticated, isSuperuser } = useAuthStore();
  const { t } = useTranslation();

  const canAccessFeature = useProfileHasAnyPermission(permission);

  if (!canAccessFeature && !isSuperuser && previousComponent) {
    return <React.Suspense fallback={<PagePreloader />}>{previousComponent}</React.Suspense>;
  }

  return (
    <React.Suspense fallback={<PagePreloader />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default PlanWrapper;
